import React, { Suspense, lazy, useState, useContext } from "react";
import { ThemeContext, themes } from "./context/ThemeContext";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
const DashboardLayout = lazy(() => import("./components/DashboardLayout"));
const Logout = lazy(() => import("./routes/authentication/pages/Logout"));
const Login = lazy(() => import("./routes/authentication/pages/Login"));
const NotFound = lazy(() => import("./components/NotFound"));

const PasswordRecovery = lazy(() =>
  import("./routes/authentication/pages/PasswordRecovery")
);
const ReviewDataUpload = lazy(() =>
  import("./routes/data-upload/pages/ReviewDataUpload")
);
const AcceptInvitation = lazy(() =>
  import("./routes/onboarding/pages/AcceptInvitation")
);
const OnboardingWizard = lazy(() =>
  import("./routes/onboarding/pages/OnboardingWizard")
);
const PasswordReset = lazy(() =>
  import("./routes/authentication/pages/PasswordReset")
);

function App() {
  const toggleTheme = (mode) => {
    setInitialState({
      ...initialState,
      theme: themes[mode],
    });
    localStorage.setItem("appTheme", mode);
  };

  const [initialState, setInitialState] = useState({
    theme: themes[localStorage.getItem("appTheme") || "dark"],
    toggleTheme: toggleTheme,
  });

  const context = useContext(ThemeContext);
  const { theme } = context;
  const GlobalStyle = createGlobalStyle`
  body {
    background:  ${(props) => {
      return props.appTheme.backgroundColor;
    }};
  }
  `;

  return (
    <>
      <GlobalStyle appTheme={theme} />
      <ThemeContext.Provider value={initialState}>
        <Router>
          <Suspense
            fallback={
              <div className="w-screen mx-auto text-xs font-normal h-screen flex items-center justify-center">
                <Loading />
              </div>
            }
          >
            <Switch>
              {/* Authentication Routes */}
              <Route exact path="/" component={Login} />
              <Route exact path="/logout-success" component={Logout} />
              <Route
                exact
                path="/recover-password"
                component={PasswordRecovery}
              />
              <Route
                exact
                path="/invitation/:action"
                component={AcceptInvitation}
              />
              <Route exact path="/onboarding" component={OnboardingWizard} />
              <PrivateRoute exact path="/data-upload/review">
                <ReviewDataUpload />
              </PrivateRoute>
              {/* Main Dashboard Layout */}
              <PrivateRoute exact path="/municipio">
                <DashboardLayout />
              </PrivateRoute>
              <PrivateRoute path="/municipio/miembros">
                <DashboardLayout />
              </PrivateRoute>
              <PrivateRoute path="/municipio/account-settings">
                <DashboardLayout />
              </PrivateRoute>
              <PrivateRoute path="/municipio/miembros/nuevo">
                <DashboardLayout />
              </PrivateRoute>
              <PrivateRoute path="/municipio/escenarios_guardados">
                <DashboardLayout />
              </PrivateRoute>
              <Route exact path="/404_page_not_found" component={NotFound} />

              {/* <PrivateRoute path="/municipio/data-upload/review">
          <DashboardLayout />
        </PrivateRoute> */}
              <PrivateRoute path="/municipio/data-upload/nuevo">
                <DashboardLayout />
              </PrivateRoute>
              <PrivateRoute path="/municipio/data-upload">
                <DashboardLayout />
              </PrivateRoute>
              <PrivateRoute path="/municipio/modulos/:moduleId">
                <DashboardLayout />
              </PrivateRoute>
              <PrivateRoute path="/municipio/miembros/:userId/editar">
                <DashboardLayout />
              </PrivateRoute>
              <Route
                path="/password-reset/:uid/:token"
                component={PasswordReset}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </ThemeContext.Provider>
    </>
  );
}

export default App;
