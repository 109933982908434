import React from "react";
import WhiteLogo from "../assets/dark-theme-logo.svg";
import Logo from "../assets/logo.svg";
import padlockLightMode from "../assets/padlock.png";
import padlockDarkMode from "../assets/padlock-dark-theme.png";

export const themes = {
  light: {
    appLogo: Logo,
    invertedLogo: WhiteLogo,
    padlockIcon: padlockLightMode,
    background: "bg-ghost-white",
    secondaryBackground: "bg-content-base",
    backgroundColor: "#F4F6FC",
    darkBackground: "bg-oxford-blue",
    darkBackgroundText: "text-white",
    text: "text-jet-black",
    indicatorDividerColor: "border-gray-500",
    lightText: "text-sonic-silver",
    textColor: "#323232",
    contentBackground: "bg-white",
    activeTab: "bg-oxford-blue",
    navBorder: "border-b border-platinum",
    checkbox: "text-jet-black border border-jet-black",
    link: "text-light-prussian",
    sideNav: {
      name: "text-eerie-black",
      subText: "text-sonic-silver",
      menuHeader: "text-silver-chalice",
      menuItem: "text-sonic-silver",
      dividerColor: "border-content-base",
      background: "bg-white",
      activeMenu: "text-oxford-blue font-medium",
      menuItemHover: "hover:bg-content-base",
    },
    table: {
      head: "text-jet-black",
      zeroStateBackground: "bg-white",
      rowBackground: "bg-white hover:bg-content-panel",
      rowText: "text-jet-black",
      iconHover: "hover:bg-cyber-yellow",
      popover: {
        background: "bg-white",
        text: "text-jet",
        hover: "hover:underline hover:text-light-prussian",
      },
      pagination: {
        buttonDisabled: "bg-gray-100 border-gray-200",
        button: "bg-transparent border-light-prussian hover:bg-light-prussian",
        icon: "text-light-prussian hover:text-white",
        iconDisabled: "text-muted",
        count: "text-jet-black",
      },
    },
    button: {
      secondary: "hover:bg-content-base text-oxford-blue",
      yellow:
        "hover:bg-white hover:text-jet-black hover:border-jet-black border border-cyber-yellow bg-cyber-yellow text-jet-black",
      outline_blue: "bg-transparent border-oxford-blue text-jet-black",
      black:
        "hover:bg-white hover:text-jet-black hover:bg-transparent border border-jet-black bg-jet-black text-white",
      primary:
        "rounded-full hover:bg-white hover:text-jet-black hover:border-jet-black border border-cyber-yellow bg-cyber-yellow text-jet-black",
    },
    moduleCard: {
      title: "text-space-cadet",
    },
    mode: "light",
    module: {
      infoBg: "bg-oxford-blue projection-mode-box-shadow",
      metric: {
        text: "text-eerie-black",
        warning: "text-dark-yellow",
        danger: "text-neon-red",
        normal: "text-success-green",
      },
      indicador_circle: {
        warning: "bg-dark-yellow",
        danger: "bg-neon-red",
        normal: "bg-neon-green",
      },
    },
    // select: "border border-black text-white",,
  },
  dark: {
    appLogo: WhiteLogo,
    invertedLogo: Logo,
    padlockIcon: padlockDarkMode,
    background: "bg-oxford-blue",
    backgroundColor: "#02223B",
    secondaryBackground: "bg-primary-dark",
    text: "text-ghost-white",
    darkBackgroundText: "text-oxford-blue",
    darkBackground: "bg-ghost-white",
    lightText: "text-silver-chalice",
    textColor: "#F4F6FC",
    contentBackground: "bg-primary-dark",
    activeTab: "bg-primary-dark",
    navBorder: "border-b border-prussian-blue",
    checkbox: "text-white border border-platinum",
    link: "text-linked-text",
    indicatorDividerColor: "border-silver-chalice",
    sideNav: {
      name: "text-white",
      subText: "text-ghost-white",
      menuHeader: "text-sonic-silver",
      menuItem: "text-silver-chalice",
      background: "bg-prussian-blue",
      dividerColor: "border-dark-divider",
      activeMenu: "text-white font-medium",
      menuItemHover: "hover:bg-oxford-blue",
    },
    table: {
      head: "text-ghost-white",
      zeroStateBackground: "bg-primary-dark",
      rowBackground: "bg-primary-dark hover:bg-oxford-blue",
      rowText: "text-white",
      iconHover: "hover:bg-primary-dark",
      popover: {
        background: "bg-white",
        text: "text-jet",
        hover: "hover:underline hover:text-light-prussian",
      },
      pagination: {
        buttonDisabled:
          "bg-primary-dark border-light-prussian cursor-not-allowed",
        button: "bg-transparent border-ghost-white hover:bg-light-prussian",
        icon: "text-ghost-white hover:text-white",
        iconDisabled: "text-light-prussian",
        count: "text-ghost-white",
      },
    },
    button: {
      secondary: "hover:bg-primary-dark text-ghost-white",
      outline_blue: "bg-transparent border-platinum text-ghost-white",
      yellow:
        "hover:bg-transparent hover:text-cyber-yellow hover:border-cyber-yellow border border-cyber-yellow bg-cyber-yellow text-jet-black",
      black:
        "border border-ghost-white bg-ghost-white text-jet-black hover:text-white hover:bg-transparent",
      primary:
        "rounded-full hover:tracking-wide hover:border-oxford-blue border border-oxford-blue bg-oxford-blue text-ghost-white",
    },
    moduleCard: {
      title: "text-ghost-white",
    },
    // select: "border border-ghost-white text-white",
    mode: "dark",
    module: {
      infoBg: "bg-oxford-blue projection-mode-box-shadow",
      metric: {
        text: "text-ghost-white",
        warning: "text-dark-yellow",
        danger: "text-neon-red",
        normal: "text-neon-green",
      },
      indicador_circle: {
        warning: "bg-dark-yellow",
        danger: "bg-neon-red",
        normal: "bg-neon-green",
      },
    },
  },
};

export const ThemeContext = React.createContext({
  theme: themes[localStorage.getItem("appTheme") || "dark"],
  toggleTheme: (mode) => {
    localStorage.setItem("appTheme", mode);
  },
});

ThemeContext.displayName = "AppTheme";
