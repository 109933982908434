import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { usersReducer } from "../reducers/UsersReducer";

// import then add reducers here as the app grows
const reducers = combineReducers({
  usersReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["usersReducer"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [],
});
const persistor = persistStore(store);
export { store, persistor };
