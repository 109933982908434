import { createReducer } from "@reduxjs/toolkit";
import {
  // eslint-disable-next-line no-unused-vars
  SET_AUTH_TOKEN,
  // eslint-disable-next-line no-unused-vars
  SET_LOGGED_IN_STATE,
  // eslint-disable-next-line no-unused-vars
  LOGOUT_USER,
  // eslint-disable-next-line no-unused-vars
  SET_NOTICE,
  // eslint-disable-next-line no-unused-vars
  TOGGLE_NOTICE,
  // eslint-disable-next-line no-unused-vars
  SET_USER_PERMISSIONS,
  // eslint-disable-next-line no-unused-vars
  SET_AUTH_REDIRECT_PATH,
  // eslint-disable-next-line no-unused-vars
  SET_ONBOARDING_TOKEN,
} from "../constants/ActionTypes";

export const initialState = {
  authenticationToken: null,
  permissions: null,
  onboarding: null,
  loggedInState: "NOT_LOGGED_IN",
  authRedirectPath: "",
  notice: {
    display: false,
    status: "",
    message: "",
    helperAction: null,
    target: null,
  },
};

export const usersReducer = createReducer(initialState, {
  SET_AUTH_TOKEN: (state, action) => {
    const { token } = action;
    return {
      ...state,
      authenticationToken: token,
    };
  },
  SET_LOGGED_IN_STATE: (state, action) => {
    const { status } = action;
    return {
      ...state,
      loggedInState: status,
    };
  },
  SET_USER_PERMISSIONS: (state, action) => {
    const { permissions } = action;
    return {
      ...state,
      permissions: permissions,
    };
  },
  SET_ONBOARDING_TOKEN: (state, action) => {
    const { data } = action;
    return {
      ...state,
      onboarding: data,
    };
  },
  SET_NOTICE: (state, action) => {
    const { status, message, helperAction, target } = action;
    return {
      ...state,
      notice: {
        display: true,
        status,
        message,
        helperAction,
        target,
      },
    };
  },
  SET_AUTH_REDIRECT_PATH: (state, action) => {
    return {
      ...state,
      authRedirectPath: action.path,
    };
  },
  TOGGLE_NOTICE: (state, action) => {
    const { mode } = action;
    return {
      ...state,
      notice: {
        status: "",
        message: "",
        helperAction: null,
        target: null,
        display: mode,
      },
    };
  },
  LOGOUT_USER: () => {
    return initialState;
  },
});
