// import and start Bugsnag with the React middleware
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import "./tailwind.generated.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "./store/index";

const bugsnagKey = process.env.REACT_APP_BUGSNAG_API_KEY;
let ErrorBoundary;
if (bugsnagKey) {
  Bugsnag.start({
    apiKey: bugsnagKey,
    plugins: [new BugsnagPluginReact()],
    autoTrackSessions: false,
  });
  ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
}
document.addEventListener("DOMContentLoaded", () => {
  if (bugsnagKey) {
    ReactDOM.render(
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* <React.StrictMode> causes Material UI error, will not impact production */}
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </PersistGate>
        </Provider>
      </ErrorBoundary>,
      document.getElementById("root")
    );
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <React.StrictMode> causes Material UI error, will not impact production */}
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </PersistGate>
      </Provider>,
      document.getElementById("root")
    );
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
