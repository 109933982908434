// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        rest.loggedInStatus ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
const mapStateToProps = (state) => {
  return {
    loggedInStatus: state.usersReducer.loggedInState === "LOGGED_IN",
  };
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  loggedInStatus: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(PrivateRoute);
